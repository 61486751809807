<template>
  <login-wrapper>
    <template v-slot:header>
      <zubut-image />
    </template>

    <template v-slot:section>
      <p class="title">Temporalmente fuera de servicio</p>
      <p>Por favor intenta iniciar sesión más tarde.</p>
      <router-link :to="{ name: 'login' }">
        Ingresar a Zubut
      </router-link>
    </template>

    <template v-slot:footer>
      <login-footer />
    </template>
  </login-wrapper>
</template>

<script>
import LoginFooter from "./LoginFooter.vue";
import LoginWrapper from "./LoginWrapper.vue";
import ZubutImage from "./ZubutImage.vue";
export default {
  name: "Reconnect",
  components: { LoginWrapper, ZubutImage, LoginFooter }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 2rem;
  font-weight: 700;
}
</style>
